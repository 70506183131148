.header {
  position: relative;
  width: 100vw;
  height: 700px;
  z-index: 1;
}

.header > div {
  position: absolute;
  width: 80%;
  height: 750px;
  margin: 0 0 0 calc(100% - 80%);
  background-color: #094284;
  border-radius: 0 0 0 100px;
  z-index: 1;
}

@media (max-width: 800px) and (min-width: 500px) {
  .header > div {
    height: 850px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .header > div {
    width: 90%;
    height: 680px;
    margin: 0 0 0 calc(100% - 90%);
  }
}

.header > div > img {
  width: 550px;
  height: 550px;
  object-fit: contain;
  margin: 150px 0 0 calc(1000px - 500px);
  border-radius: 0 0 0 50px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .header > div > img {
    width: 450px;
    height: 450px;
    margin: 150px 0 0 calc(1000px - 580px);
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .header > div > img {
    width: 450px;
    height: 450px;
    margin: 150px 0 0 calc(1000px - 650px);
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .header > div > img {
    width: 400px;
    height: 400px;
    margin: 450px 0 0 calc(1000px - 900px);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .header > div > img {
    width: 270px;
    height: 270px;
    margin: 350px 0 0 10%;
  }
}

.header > h3 {
  top: 250px;
  position: absolute;
  padding: 20px 50px 20px 70px;
  background-color: white;
  font-size: 50px;
  z-index: 2;
  font-weight: 700;
  color: #222222;
  border-radius: 0 20px 20px 0;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .header > h3 {
    font-size: 45px;
    padding: 20px 30px 20px 50px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .header > h3 {
    font-size: 40px;
    padding: 20px 30px 20px 50px;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .header > h3 {
    top: 200px;
    font-size: 35px;
    padding: 20px 30px 20px 30px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .header > h3 {
    top: 150px;
    font-size: 24px;
    padding: 20px 30px 20px 30px;
  }
}

.header > h4 {
  top: 420px;
  position: absolute;
  padding: 20px 50px 20px 70px;
  background-color: white;
  font-size: 50px;
  z-index: 3;
  font-weight: 700;
  color: #222222;
  border-radius: 0 20px 20px 0;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .header > h4 {
    font-size: 45px;
    padding: 20px 30px 20px 50px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .header > h4 {
    font-size: 40px;
    padding: 20px 30px 20px 50px;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .header > h4 {
    top: 350px;
    font-size: 35px;
    padding: 20px 30px 20px 30px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .header > h4 {
    top: 250px;
    font-size: 24px;
    padding: 20px 30px 20px 30px;
  }
}
