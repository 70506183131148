.media_nav {
  position: fixed;
  width: 80vw;
  height: 85px;
  padding: 0 10vw;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 2px #d5d5d5;
  background-color: white;
}

.media_nav_icon {
  margin: 10px 0;
  display: flex;
}

.media_nav_icon > img {
  height: 25px;
  margin: 17.5px 0;
}

.media_nav_icon > h2 {
  line-height: 60px;
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  white-space: nowrap;
  /* font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif; */
}

.media_nav_contact {
  border: none;
  font-size: 15px;
  color: #222222;
  font-weight: bold;
  line-height: 60px;
  margin: 10px 0px;
}

.media_nav_contact > p {
  /* font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif; */
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .media_nav {
    width: calc(90% - 100px);
    margin: 30px 5% 0;
  }
}

@media (max-width: 1000px) and (min-width: 450px) {
  .media_nav {
    width: 90%;
    margin: 0;
    padding: 0 5%;
  }
}

@media (max-width: 450px)  {
  .media_nav {
    width: 90%;
    margin: 0px 0% 0;
    padding: 0 5%;
  }
}
