.result {
  width: 1100px;
  padding: 150px calc((100% - 1100px) / 2) 200px;
  display: flex;
  justify-content: space-between;
  background-color: #EDF2F6;
  min-height: 100vh;
}

@media (max-width: 1100px) and (min-width: 700px) {
  .result {
    width: 700px;
    padding: 150px calc((100% - 700px) / 2) 0;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .result {
    width: 100vw;
    padding: 100px calc((100% - 700px) / 2) 0;
  }
}

/* category_box */

.category_box {
  background-color: #EDF2F6;
  padding: 100px 0 0;
}

@media (max-width: 500px) and (min-width: 0px) {
  .category_box {
    padding: 10px 0 0;
  }
}


.category_box_title {
  width: 1100px;
  margin: 0px calc((100% - 1100px) / 2) 50px;
  font-size: 20px;
  color: #222222;
}

@media (max-width: 500px) and (min-width: 0px) {
  .category_box_title {
    width: 100%;
    margin: 50px auto 50px;
    text-align: center;
  }
}


.results_box {
  width: 700px;
  background-color: white;
}

@media (max-width: 700px) and (min-width: 0px) {
  .results_box {
    width: 100vw;
    background-color: white;
  }
}


.results_box_order {
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #094284;
  margin: 50px 5% 50px;
}

.results_box_order > button {
  border: none;
  width: 32%;
  background-color: white;
  color: #094284;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
  border: 1px solid #0032A0;
  border-bottom: none;
}






.list {
  width: 700px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .list {
    width: 100vw;
  }
}

.box {
  width: 100%;
  height: 450px;
  background-color: white;
  margin: 30px 0% 30px;
}

@media (max-width: 700px) and (min-width: 500px) {
  .box {
    width: 100vw;
    height: 550px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .box {
    width: 100vw;
    height: 700px;
  }
}



.box_header {
  width: 90%;
  padding: 20px 5% 0;
  display: flex;
  justify-content: space-between;
}

.box_header > h6 {
  width: 60px;
  height: 60px;
  background-color: #094284;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-height: 60px;
}

.box_header_name {
  width: calc(100% - 250px);
  height: 55px;
  margin: 2.5px 0;
}

@media (max-width: 500px) and (min-width: 0px) {
  .box_header_name {
    width: calc(100% - 80px);
  }
}

.box_header_name > h3 {
  font-size: 14px;
  line-height: 25px;
  color: #666666;

  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.box_header_name > h2 {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.box_header_btn {
  width: 140px;
  display: flex;
  background-color: #094284;
  justify-content: space-around;
}


.box_header_btn > p {
  line-height: 60px;
  font-size: 14px;
  color: white;
}

.box_header_btn_input {
  width: 14px;
  height: 14px;
  margin: 23px 0;
  accent-color: #1F2C5B;
  background-color: white;
  border: .5px solid #222222;
}

@media (max-width: 500px) and (min-width: 0px) {
  .box_header_btn {
    display: none;
  }
}

.box_contents {
  width: 90%;
  margin: 30px 5% 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 700px) and (min-width: 0px) {
  .box_contents {
    display: block;
  }
}

.box_contents > img {
  width: 200px;
  height: 310px;
  object-fit: contain;
  border: .5px solid #cccccc;
}

@media (max-width: 700px) and (min-width: 0px) {
  .box_contents > img {
    width: 100%;
    height: 200px;
  }
}

/* table */

.api_media_table {
  width: 410px;
  border: 1px solid #222222;
  border-collapse: collapse;
  /* margin-bottom: 100px; */
}

@media (max-width: 700px) and (min-width: 0px) {
  .api_media_table {
    width: 100%;
    margin-top: 10px;
  }
}

.api_media_table > * p {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.api_media_q {
  width: 70px;
  background-color: #EDF2F6;
  color: #222222;
  border: 1px solid #cccccc;
  font-weight: bold;
}

.api_media_q > p {
  padding: 5px 5px;
  font-size: 14px;
}

.api_media_a {
  width: 240px;
  background-color: white;
  color: #222222;
  text-align: left;
  border: 1px solid #cccccc;
}

.api_media_a > p {
  font-size: 14px;
  padding: 5px 5px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  line-height: 25px;
}

.api_media > a > button {
  border: none;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  line-height: 40px;
  color: #222222;
  background-color: #EB6231;
  color: white;
  border-radius: 2px;
  border: 0.5px solid #222222;
  margin: 0 0 50px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}











.estimate {
  width: 90%;
  display: flex;
  background-color: #EDF2F6;
  margin: 10px auto 0;
  justify-content: space-around;
  border: 1px solid #cccccc;
}

.estimate > p {
  line-height: 60px;
  font-size: 14px;
  color: #222222;
}

.estimate > input {
  width: 14px;
  height: 14px;
  margin: 23px 0;
  color: #094284;
}

@media (max-width: 10000px) and (min-width: 500px) {
  .estimate {
    display: none;
  }
}






.search_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .search_title {
    width: 100%;
    margin: 0px 0 50px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .search_title {
    width: 90%;
    margin: 0px 5% 50px;
  }
}

.search_title > h4 {
  font-size: 15px;
  color: #222222;
}

.search_title > h5 {
  font-size: 15px;
  color: #222222;
}


/*  */

.header {
  width: 100vw;
}

.header > h1 {
  font-size: 35px;
  line-height: 60px;
  padding: 230px 0 120px;
  background-color: #094284;
  text-align: center;
  color: white;
}

@media (max-width: 500px) and (min-width: 0px) {
  .header > h1 {
    font-size: 25px;
    line-height: 40px;
  }
}

/* bid_list_number */

.list_number {
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.list_number > div {
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  border: 1px solid #0032A0;
  font-weight: bold;
  color: #0032A0;
  border-radius: 2px;
}




.animationTrue {
  position: fixed;
  z-index: 10000;
  animation-name: animationTrue;
  animation-duration: 1s;
  height: 150px;
  text-align: center;
  width: 100vw;
  animation-iteration-count: 1;
  opacity: 1;
}

@keyframes animationTrue {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animationFalse {
  position: fixed;
  z-index: 10000;
  animation-name: animationFalse;
  animation-duration: 1s;
  height: 150px;
  text-align: center;
  width: 100vw;
  animation-iteration-count: 1;
  opacity: 0;
}

@keyframes animationFalse {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-150px);
  }
}


/* search */

@media (max-width: 1100px) and (min-width: 0px) {
  .search {
    display: none;
  }
}

/* estimate_bar */

.estimate_bar {
  position: fixed;
  width: 100vw;
  height: 100px;
  padding: 0;
  display: flex;
  bottom: 0;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar {
    height: 200px;
  }
}

.estimate_bar  > div {
  position: relative;
}

.estimate_bar > div > div:nth-child(1) {
  position: absolute;
  width: 100vw;
  height: 100px;
  background-color: #000000;
  opacity: .6;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(1) {
    height: 200px;
  }
}

.estimate_bar > div > div:nth-child(2) {
  position: absolute;
  width: 60vw;
  padding: 0px 20vw;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .estimate_bar > div > div:nth-child(2) {
    width: 80vw;
    padding: 0 10vw;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .estimate_bar > div > div:nth-child(2) {
    width: 95vw;
    padding: 0 2.5vw;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) {
    width: 350px;
    padding: 0 calc((100vw - 350px) / 2);
    display: block;
  }
}

.estimate_bar > div > div:nth-child(2) > h4 {
  color: white;
  line-height: 60px;
  margin: 20px 0;
  font-size: 16px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > h4 {
    margin: 10px 0;
  }
}


.estimate_bar > div > div:nth-child(2) > h4 > span {
  color: #EB6231;
  font-size: 28px;
  margin-left: 10px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > h4 {
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }

  .estimate_bar > div > div:nth-child(2) > h4 > span {
    color: #EB6231;
    font-size: 20px;
    margin-left: 12px;
  }
}

.estimate_bar > div > div:nth-child(2) > div {
  display: flex;
  height: 60px;
  padding: 20px 0;
  width: calc(100% - 450px);
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > div {
    display: flex;
    height: 60px;
    padding: 0px 0;
    width: 100%;
  }
}

.estimate_bar > div > div:nth-child(2) > div > div {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > div > div {
    position: relative;
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }
}

.estimate_bar > div > div:nth-child(2) > div > div > div {
  top: -8px;
  right: -8px;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 3;
  background-color: #222222;
  border-radius: 100px;
}

.imCross {
  width: 8px;
  height: 8px;
  color: white;
  padding: 6px;
}

.estimate_bar > div > div:nth-child(2) > div > div > img {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  z-index: 2;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > div > div > img {
    width: 45px;
    height: 45px;
  }
}

.estimate_bar > div > div:nth-child(2) > a > button {
  width: 140px;
  height: 50px;
  background-color: #F78821;
  color: white;
  border: none;
  margin: 25px 0;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > a > button {
    width: 140px;
    margin: 0px calc((100% - 140px) / 2) 0;
    height: 40px;
  }
}




