.search_all {
  width: 60%;
  background-color: #094284;
  padding: 30px 20%;
}

@media (max-width: 1100px) and (min-width: 0px) {
  .search_all {
    width: 80%;
    background-color: #094284;
    padding: 30px 10%;
  }
}

.search_all > h4 {
  font-size: 16px;
  color: white;
  line-height: 40px;
}

.search_all > div {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.search_all > div > p {
  line-height: 30px;
  font-size: 14px;
  color: white;
  margin-right: 20px;
}



