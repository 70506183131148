.background {
  width: 100vw;
  background-color: rgb(246, 247, 255);
  padding-bottom: 100px;
}

.background > h4 {
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #222222;
  padding-top: 100px;
}

.box {
  width: 450px;
  margin: 50px calc((100% - 550px) / 2) 0;
  background-color: white;
  padding: 25px 50px;
}

.box > h4 {
  margin-right: 10px;
  font-size: 15px;
  color: #0F1213;
  line-height: 40px;
  font-weight: bold;
}

.textarea {
  height: 150px;
  padding: 5px 15px;
  width: calc(100% - 30px);
  border: none;
  border: 1px solid #cccccc;
  font-size: 14px;
  line-height: 35px;
  border-radius: 2px;
  color: #222222;
  resize: vertical;
  margin-bottom: 30px;
}

.input {
  height: 40px;
  width: calc(100% - 2px);
  border: none;
  border: 1px solid #cccccc;
  font-size: 14px;
  line-height: 35px;
  text-indent: 1em;
  border-radius: 2px;
  margin-bottom: 30px;
}

.box > button {
  border: none;
  width: 200px;
  line-height: 45px;
  height: 45px;
  margin: 0 calc((100% - 200px) / 2);
  background-color: rgb(42, 42, 148);
  color: white;
}
