.header {
  width: 100vw;
}

.header > h1 {
  font-size: 35px;
  line-height: 60px;
  padding: 230px 0 120px;
  background-color: #094284;
  text-align: center;
  color: white;
}

@media (max-width: 500px) and (min-width: 0px) {
  .header > h1 {
    font-size: 25px;
    line-height: 40px;
  }
}



.background {
  background-color: #EDF2F6;
  padding: 0 0 10px;
}







.bid_api_header_bar {
  position: fixed;
  width: 100%;
  height: 10px;
  background-color: #1F2C5B;
}

.api_header_bar {
  position: fixed;
  width: 100%;
  height: 10px;
  background-color: #1F2C5B;
}


/* left */

.api_header_left {
  position: fixed;
  top: 200px;
  left: calc((100% - 1200px) / 2);
  width: 25px;
}

.api_header_left > div {
  width: 10px;
  height: 10px;
  background-color: #E29150;
  margin: 50px 7px 0;
  border-radius: 100px;
}

.api_header_left > h3 {
  width: 25px;
  text-align: center;
  line-height: 19px;
  font-size: 15px;
  margin-top: 20px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

/* 全体 */

.contents {
  width: 1200px;
  background-color: #EDF2F6;
  padding: 150px calc((100% - 1200px) / 2) 50px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 700px) {
  .contents {
    width: 700px;
    padding: 150px calc((100% - 700px) / 2) 50px;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .contents {
    width: 100%;
    padding: 150px 0 50px;
  }
}

.contents_product {
  width: 700px;
  background-color: white;
  padding: 50px;
  border-radius: 10px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .contents_product {
    width: 90%;
    padding: 50px 5%;
  }
}

.contents_product_title {
  display: flex;
  margin: 50px 0 30px;
}

.contents_product_title > p {
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  font-size: 10px;
  border-radius: 100px;
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0;
}

.contents_product_title > h3 {
  font-size: 18px;
  color: #222222;
  line-height: 30px;
  margin-left: 20px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

/* contents_product_header */

.contents_product_header {
  display: flex;
}

.contents_product_header > h4 {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  background-color: #094284;
  color: white;
  border-radius: 3px;
}

.contents_product_header > div {
  height: 50px;
  margin: 0 0 0 20px;
}

.contents_product_header > div > h4 {
  font-size: 14px;
  color: #888888;
  line-height: 20px;
}

.contents_product_header > div > h5 {
  font-size: 14px;
  color: #222222;
  line-height: 30px;
}

/* contents_product_blog */

.contents_product_blog {
  width: 100vw;
  margin-top: 100px;
  font-size: 28px;
  color: #222222;
  text-align: center;
}

.contents_product > p {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  margin-bottom: 50px;
  white-space: pre-line;
}

/* contents_banner */

.contents_banner {
  width: 250px;
  height: 800px;
  background-color: white;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .contents_banner {
    display: none;
  }
}




.contents_product_details {
  margin: 50px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 700px) and (min-width: 0px) {
  .contents_product_details {
    margin: 50px 0;
    width: 100%;
    display: block;
  }
}


.contents_product_details > img {
  width: 250px;
  height: 420px;
  object-fit: contain;
  border: .5px solid #cccccc;
}

@media (max-width: 700px) and (min-width: 0px) {
  .contents_product_details > img {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border: .5px solid #cccccc;
    margin-bottom: 20px;
  }
}

.contents_product > h3 {
  font-size: 18px;
  color: #222222;
  margin-bottom: 20px;
}

.contents_product_table {
  width: calc(100% - 280px);
  border: 1px solid #222222;
  border-collapse: collapse;
}

@media (max-width: 700px) and (min-width: 0px) {
  .contents_product_table {
    width: 100%;
  }
}

.contents_product_table > tbody > tr > th {
  width: 25%;
  background-color: #EDF2F6;
  color: white;
  border: 1px solid #222222;
  font-weight: bold;
  text-align: left;
}

.contents_product_table > tbody > tr > th > p {
  padding: 10px 10px;
  font-size: 14px;
  color: #222222;
}

.contents_product_a {
  width: 75%;
  background-color: white;
  color: #222222;
  text-align: left;
  border: 1px solid #222222;
}

.contents_product_a > p {
  padding: 10px 10px;
  word-break: break-word;
  font-size: 14px;
}

.contents_product_a > a > p {
  padding: 10px 10px;
  word-break: break-word;
  font-size: 14px;
  color: #1F2C5B;
}

.contents_product > button {
  border: none;
  width: 200px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  line-height: 40px;
  color: #222222;
  background-color: #EB6231;
  color: white;
  border-radius: 2px;
  border: 0.5px solid #222222;
  margin: 0 0 50px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  border-radius: 100px;
  background: #094284;
  margin: 0 calc((100% - 200px) / 2);
}


/* products_list */

.recommend {
  width: 1200px;
  height: 600px;
  margin: 30px calc((100% - 1200px) / 2) 100px;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 1200px) and (min-width: 700px) {
  .recommend {
    width: 700px;
    margin: 30px calc((100% - 700px) / 2) 100px;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .recommend {
    width: 100%;
    margin: 30px 0 100px;
  }
}

.recommend > h4 {
  font-size: 26px;
  color: #094284;
  line-height: 100px;
  text-align: center;
  font-weight: 900;
  margin: 0 0 40px;
}

.recommend_contents {
  display: flex;
  width: 1000px;
  margin: 0 100px;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .recommend_contents {
    display: block;
    width: 90%;
    margin: 0 5%;
  }
}

.recommend_contents_header {
  width: 150px;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .recommend_contents_header {
    display: none;
  }
}

.recommend_contents_header > h4:nth-child(1),
.recommend_contents_header > h4:nth-child(3),
.recommend_contents_header > h4:nth-child(4),
.recommend_contents_header > h4:nth-child(5) {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #094284;
  color: white;
  text-align: center;
  margin-bottom: 1px;
}

.recommend_contents_header > h4:nth-child(2) {
  width: 100%;
  height: 120px;
  line-height: 80px;
  background-color: #094284;
  color: white;
  text-align: center;
  margin-bottom: 1px;
}

.recommend_contents_lists {
  width: calc(100% - 150px);
  margin-left: 10px;
  height: 366px;
  display: flex;
  overflow-x: scroll;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .recommend_contents_lists {
    width: 100%;
  }
}


.recommend_contents_lists_box {
  margin-right: 10px;
  height: 366px;
}

.recommend_contents_lists_box > h4:nth-child(1),
.recommend_contents_lists_box > h4:nth-child(3),
.recommend_contents_lists_box > h4:nth-child(4),
.recommend_contents_lists_box > h4:nth-child(5) {
  width: 259px;
  height: 59px;
  line-height: 60px;
  background-color: white;
  color: #222222;
  text-align: center;
  margin-bottom: 1px;
  border: .5px solid #cccccc;
}

.recommend_contents_lists_box_image {
  width: 259px;
  height: 119px;
  background-color: white;
  color: #222222;
  margin-bottom: 1px;
  border: .5px solid #cccccc;
  object-fit: contain;
}

.recommend_contents_lists_box_image > img {
  width: 100%;
  height: 119px;
  object-fit: contain;
}

.category_box {
  background-color: #EDF2F6;
  padding: 0px 0 100px;
}


.category_box_title {
  width: 1100px;
  margin: 0px calc((100% - 1100px) / 2) 50px;
  font-size: 20px;
  color: #222222;
}

@media (max-width: 1100px) and (min-width: 0px) {
  .category_box_title {
    width: 100%;
    margin: 100px 0 50px;
    font-size: 20px;
    color: #222222;
    text-align: center;
  }
}

.recommend_contents_lists_checkbox {
  width: 60%;
  border: .5px solid #cccccc;
  display: flex;
  padding: 0 20%;
  justify-content: space-between;
}

.recommend_contents_lists_checkbox > h4 {
  line-height: 60px;
}

.recommend_contents_lists_checkbox > input {
  width: 14px;
  height: 14px;
  margin: 23px 0;
  accent-color: #1F2C5B;
  background-color: white;
  border: .5px solid #222222;
}



/* estimate_bar */

.estimate_bar {
  position: fixed;
  width: 100vw;
  height: 100px;
  padding: 0;
  display: flex;
  bottom: 0;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar {
    height: 200px;
  }
}

.estimate_bar  > div {
  position: relative;
}

.estimate_bar > div > div:nth-child(1) {
  position: absolute;
  width: 100vw;
  height: 100px;
  background-color: #000000;
  opacity: .6;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(1) {
    height: 200px;
  }
}

.estimate_bar > div > div:nth-child(2) {
  position: absolute;
  width: 60vw;
  padding: 0px 20vw;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .estimate_bar > div > div:nth-child(2) {
    width: 80vw;
    padding: 0 10vw;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .estimate_bar > div > div:nth-child(2) {
    width: 95vw;
    padding: 0 2.5vw;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) {
    width: 350px;
    padding: 0 calc((100vw - 350px) / 2);
    display: block;
  }
}

.estimate_bar > div > div:nth-child(2) > h4 {
  color: white;
  line-height: 60px;
  margin: 20px 0;
  font-size: 16px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > h4 {
    margin: 10px 0;
  }
}


.estimate_bar > div > div:nth-child(2) > h4 > span {
  color: #EB6231;
  font-size: 28px;
  margin-left: 10px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > h4 {
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }

  .estimate_bar > div > div:nth-child(2) > h4 > span {
    color: #EB6231;
    font-size: 20px;
    margin-left: 12px;
  }
}

.estimate_bar > div > div:nth-child(2) > div {
  display: flex;
  height: 60px;
  padding: 20px 0;
  width: calc(100% - 450px);
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > div {
    display: flex;
    height: 60px;
    padding: 0px 0;
    width: 100%;
  }
}

.estimate_bar > div > div:nth-child(2) > div > div {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > div > div {
    position: relative;
    width: 45px;
    height: 45px;
    margin-right: 20px;
  }
}

.estimate_bar > div > div:nth-child(2) > div > div > div {
  top: -8px;
  right: -8px;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 3;
  background-color: #222222;
  border-radius: 100px;
}

.imCross {
  width: 8px;
  height: 8px;
  color: white;
  padding: 6px;
}

.estimate_bar > div > div:nth-child(2) > div > div > img {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  z-index: 2;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > div > div > img {
    width: 45px;
    height: 45px;
  }
}

.estimate_bar > div > div:nth-child(2) > a > button {
  width: 140px;
  height: 50px;
  background-color: #F78821;
  color: white;
  border: none;
  margin: 25px 0;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .estimate_bar > div > div:nth-child(2) > a > button {
    width: 140px;
    margin: 0px calc((100% - 140px) / 2) 0;
    height: 40px;
  }
}




