
.site_desc {
  width: 100%;
  padding: 50px 0;
  margin: 0px 0 0;
}

.site_desc > h4 {
  text-align: center;
  font-size: 20px;
  color: #222222;
}


.site_desc_point {
  width: 1000px;
  padding: 100px calc((100% - 1000px) / 2) 100px;
  background-color: #EDF2F6;
  margin: 0px 0 0;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .site_desc_point {
    width: 800px;
    padding: 100px calc((100% - 800px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .site_desc_point {
    width: 600px;
    padding: 100px calc((100% - 600px) / 2);
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .site_desc_point {
    width: 500px;
    padding: 100px calc((100% - 500px) / 2);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .site_desc_point {
    width: 95%;
    padding: 100px 2.5%;
  }
}

.site_desc_point > h4 {
  font-size: 33px;
  color: #222222;
  text-align: center;
}

.site_desc_point > p {
  width: 700px;
  margin: 30px calc((100% - 700px) / 2);
  font-size: 14px;
  color: #222222;
  text-align: center;
  line-height: 30px;
}

@media (max-width: 800px) and (min-width: 600px) {
  .site_desc_point > p {
    width: 100%;
    margin: 30px 0 0;
  }

  .site_desc_point > p > br {
    display: none;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .site_desc_point > p {
    width: 90%;
    margin: 30px 5% 0;
  }

  .site_desc_point > p > br {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .site_desc_point > p {
    width: 95%;
    margin: 30px 2.5% 0;
    font-size: 12px;
    line-height: 23px;
  }

  .site_desc_point > p > br {
    display: none;
  }
}

.site_desc_point > div {
  width: 1000px;
  margin: 70px calc((100% - 1000px) / 2) 0;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .site_desc_point > div {
    width: 650px;
    margin: 70px calc((100% - 650px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .site_desc_point > div {
    width: 600px;
    margin: 70px calc((100% - 600px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .site_desc_point > div {
    width: 300px;
    margin: 70px calc((100% - 300px) / 2) 0;
    display: block;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .site_desc_point > div {
    width: 300px;
    margin: 70px calc((100% - 300px) / 2) 0;
    display: block;
  }
}

.site_desc_point > div > div {
  width: 260px;
  height: 300px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  background-color: white;
  padding: 20px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .site_desc_point > div > div:nth-child(3) {
    display: none;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .site_desc_point > div > div:nth-child(3) {
    display: none;
  }
  
  .site_desc_point > div > div {
    padding: 20px 10px;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .site_desc_point > div > div {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .site_desc_point > div > div {
    margin-bottom: 20px;
  }
}

.site_desc_point > div > div > div {
  display: flex;
}

.site_desc_point > div > div > div > h4 {
  font-size: 26px;
  line-height: 40px;
}

.site_desc_point > div > div > div > p {
  font-size: 14px;
  color: #222222;
  line-height: 40px;
  font-weight: bold;
  padding: 0 0 0 10px;
}

.site_desc_point > div > div > p {
  font-size: 12px;
  color: #222222;
  line-height: 25px;
}

.site_desc_point > div > div > img {
  width: 100%;
  margin-top: 20px;
}


.site_desc_point > button {
  width: 200px;
  height: 50px;
  color: white;
  font-weight: bold;
  background-color: #094284;
  border: none;
  border-radius: 2px;
  margin: 50px calc((100% - 200px) / 2) 0;
}