/* title */

.industry_title {
  font-size: 20px;
  width: calc(90vw - 50px);
  line-height: 40px;
  color: white;
  text-align: center;
  font-weight: 600;
  background-color: #094284;
  padding: 15px;
  margin: 0 auto 0;
  border-radius: 50px;
  z-index: 2;
}

/* new */

.industry {
  width: 90%;
  padding: 130px 5% 150px;
  margin-top: -100px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.industry_open {
  width: 100%;
  margin: 20px 0 0px;
  border-radius: 5px;
  overflow: hidden;
  height: 70px;
  transition: height 0.7s;
  background-color: white;
}

.industry_close {
  width: 100%;
  margin: 20px 0 0px;
  border-radius: 5px;
  overflow: hidden;
  height: 290px;
  transition: height 0.7s;
  background-color: white;
}

.industry_contents_title {
  width: 50%;
  margin: 10px 5%;
  display: flex;
  justify-content: space-between;
  background-color: #FF9B04;
  padding: 5px 20%;
  border-radius: 5px;
}

.industry_contents_title > h4 {
  width: 50%;
  font-size: 14px;
  color: white;
  line-height: 40px;
  text-align: left;
}

.industry_contents_title > img {
  width: 35px;
  height: 35px;
  margin: 2.5px;
  object-fit: contain;
}

.photo_question_hr {
  width: calc(70vw - 80px);
  height: 1px;
  margin: 10px 40px;
  background-color: #dddddd;
}

.industry_contents_items {
  margin: 10px 10% 0;
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
}

.industry_contents_items > h5 {
  line-height: 40px;
  color: #222222;
}

.industry_contents_icon {
  width: 20px;
  height: 20px;
  color: #222222;
  margin: 10px 0;
}


