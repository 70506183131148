

.media {
  width: 85%;
  padding: 170px 7.5% 100px;
  display: flex;
  justify-content: space-between;
  background-color: #f7fafd;
}

.media > * > p,
.media > * > p > strong,
.media > * > h1,
.media > * > h2,
.media > * > h3,
.media > * > h4,
.media > * > h5,
.media > * > h6,
.media > * > .media_contents_title,
.media > * > a {
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .media {
    width: 90%;
    padding: 170px 5% 100px;
  }
}

@media (max-width: 1000px) and (min-width: 450px) {
  .media {
    width: 90%;
    padding: 170px 5% 100px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .media {
    width: 100%;
    padding: 100px 0 100px;
    display: block;
  }
}


.media_article {
  width: 750px;
  padding-bottom: 150px;
  background-color: white;
}

@media (max-width: 1350px) and (min-width: 1299px) {
  .media_article {
    width: 650px;
  }
}

@media (max-width: 1200px) and (min-width: 1150px) {
  .media_article {
    width: 650px;
  }
}

@media (max-width: 1150px) and (min-width: 1100px) {
  .media_article {
    width: 600px;
  }
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .media_article {
    width: 550px;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_article {
    width: 100%;
  }
}

.media_date {
  padding: 50px 5% 0;
  width: 90%;
  color: #666666;
  font-size: 13px;
  text-align: left;
  font-weight: bold;
}

.media_title {
  text-align: left;
  padding: 20px 0 15px;
  width: 90%;
  color: #444444;
  font-size: 20px;
  border-bottom: 3px solid #006f9e;
  margin: 0px 5% 50px;
}

.media_img {
  width: 100%;
}

.media_title_img {
  width: 90%;
  margin: 0 5%;
}


.media_introduction {
  margin: 50px 5%;
  width: 80%;
  padding: 3% calc(5% - 6px);
  text-align: left;
  border: 3px solid #E9973E;
  background-color: #FEF9E7;
  border-radius: 5px;
}

.media_introduction > p {
  margin: 0px 0 0;
  width: 100%;
  font-size: 14px;
  line-height: 2.3em;
  color: #333333;
  text-align: left;
  word-break: break-all;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_introduction {
    margin: 80px 5% 0;
    width: 80%;
    padding: 3%  calc(5% - 6px);
    text-align: left;
    border: 3px solid #E9973E;
    background-color: #FEF9E7;
    border-radius: 5px;
  }

  .media_introduction > ul {
    text-indent: -1em;
    padding-left: 1em;
    margin-left: 20px;
  }
}

.media_introduction > div {
  display: flex;
}

.media_introduction_icon {
  width: 30px;
  height: 30px;
  color: #E9973E;
}

.media_introduction > div > h3 {
  font-size: 18px;
  color: #E9973E;
  line-height: 30px;
  margin-left: 10px;
}

.media_introduction > hr {
  width: 100%;
  margin-top: 20px;
  border-top: 1px dashed #E9973E;
}

.media_introduction > ul {
  width: 90%;
  color: #E9973E;
  list-style-position: inside;
  padding: 20px 0 0 0;
}

.media_introduction > ul > li > span {
  width: 90%;
  padding: 20px 0 0 0;
  color: #444444;
  font-weight: bold;
  line-height: 37px;
  text-indent: -10px;
}


.media_contents {
  margin: 80px 5% 0;
  width: 90%;
  text-align: left;
  border: 1px solid #006f9e;
  background-color: #fcfcfc;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_contents {
    margin: 80px 5% 0;
    width: 90%;
  }
}

.media_contents_title {
  width: 95%;
  padding: 0 0 0 5%;
  height: 60px;
  background-color: #006f9e;
  color: white;
  line-height: 60px;
  font-weight: bold;
}

.media_contents_contents {
  padding: 30px 5%;
}

.media_contents_contents > ol {
  font-size: 15px;
  line-height: 40px;
  color: #464646;
  padding-left: 18px;
}

.media_contents_contents > ol > ul {
  font-size: 15px;
  line-height: 35px;
  color: #464646;
  padding-left: 30px;
}

.media_contents_contents > ol > li > a {
  text-decoration: none;
  color: #464646;
}

.media_contents_contents > ol > ul > li > a {
  text-decoration: none;
  color: #464646;
}


.media_text_title > h2 {
  margin: 80px 5% 0;
  width: 84%;
  padding: 0 3%;
  text-align: left;
  height: 60px;
  line-height: 60px;
  background-color: #1086c1;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_text_title > h2 {
    margin: 80px 5% 0;
    width: 84%;
    padding: 0 3%;
  }
}


.media_text_text {
  margin: 0px 5% 0;
  width: 90%;
  font-size: 15px;
  line-height: 2.3em;
  color: #333333;
  text-align: left;
  word-break: break-all;
}

.media_text_text > span {
  color: #444444;
  font-size: 15px;
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  padding: 0 3px;
  background: linear-gradient(transparent 60%, #ffdfbb 60%);
}


@media (max-width: 450px) and (min-width: 0px) {
  .media_text_text {
    margin: 0px 5% 0;
    width: 90%;
  }
}

.media_text_text > a {
  text-decoration: none;
  background-color: white;
  margin: 10px 0;
  color: #347abb;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  border-bottom: 1px solid #347abb;
}

.media_text_text_title {    
  margin: 50px 5% 10px;
  width: 85%;
  padding: 5px 2.5% 5px calc(2.5% - 5px);
  font-size: 15px;
  line-height: 30px;
  color: #464646;
  text-align: left;
  border-left: 5px solid #1086c1;
  padding-left: 10px;
  line-height: 40px;
  background-color: #fafafa;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_text_text_title {
    margin: 0px 5% 0;
    width: 85%;
    padding: 5px 2.5% 5px calc(2.5% - 5px);
    font-size: 15px;
    line-height: 30px;
    color: #464646;
    text-align: left;
    border-left: 5px solid #1086c1;
    padding-left: 10px;
    line-height: 40px;
    background-color: #fafafa;
  }
}

.media_text_text_box {
  margin: 30px 5% 0;
  width: 80%;
  border: .5px solid #666666;
  font-size: 15px;
  line-height: 32px;
  color: #464646;
  text-align: left;
  background-color: #fafafa;
  padding: 15px 5% 15px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_text_text_box {
    margin: 30px 5% 0;
    width: 80%;
  }
}

.media_text_text_box_title {
  color: #444444;
  font-size: 15px;
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  padding: 0 3px;
  background: linear-gradient(transparent 60%, #abc0d6 60%);
}

.media_text_text_box_site {
  color: #1086c1;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  float: right;
}


.media_table {
  margin: 30px 5% 0;
  width: 90%;
  border-collapse: collapse;
  text-align: left;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_table {
    margin: 30px 5% 0;
    width: 90%;
    border-collapse: collapse;
    text-align: left;
  }
}

th {
  color: white;
  background-color: #056595;
  border: 1.5px solid rgb(24, 66, 109);
}

th {
  border: 1.5px solid rgb(24, 66, 109);
  font-size: 15px;
  font-size: 15px;
  line-height: 35px;
  margin-left: 18px;
  padding: 0 2%;
  color: white;
}

td {
  border: 1.5px solid rgb(24, 66, 109);
  font-size: 15px;
  font-size: 15px;
  margin-left: 18px;
  padding: 3% 10px;
  color: #464646;
}

.media_table_0001_1_1 {
  width: 30%;
  color: white;
}

.media_table_0001_1_2 {
  width: 20%;
  color: white;
}

.media_table_0001_1_3 {
  width: 20%;
  color: white;
}

.media_table_0001_2_1 {
  width: 15%;
}

.media_table_0001_2_2 {
  width: 15%;
}

.media_table_0001_2_3 {
  width: 35%;
}

.media_table_0001_3_1 {
  width: 30%;
}

.media_table_0001_3_2 {
  width: 70%;
}

.media_table_0001_4_1 {
  width: 21%;
}

.media_table_0001_4_2 {
  width: 11%;
}

.media_table_0001_5_1 {
  width: 10%;
}

.media_table_4_o {
  text-align: center;
}

.media_table_4_x {
  text-align: center;
  background-color: #eaeaea;
}

.media_table_box {
  margin: 30px 0 0;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.media_table_0009_5_1 {
  width: 16%;
}

.media_table_0009_5_2 {
  width: 14%;
}


.tooltip {
  position: relative;
  cursor: pointer;
  padding: 0 3px;
  border: 1px solid #c4c4c4;
  color: #1086c1;
  border-radius: 2px;
  margin: 0 3px;
}

.tooltip:hover .tooltip_text {
  opacity: 1;
  visibility: visible;
}

.tooltip_text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0%;
  bottom: -35px;
  display: inline-block;
  padding: 5px 10px;
  white-space: nowrap;
  font-size: 10.5px;
  line-height: 2;
  background: #1086c1;
  color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in;
  box-shadow: 0 1px 2px #d5d5d5;
}

.tooltip_text:before {
  content: '';
  position: absolute;
  top: -13px;
  left: 10%;
  margin-left: -7px;
  border: 7px solid transparent;
  border-bottom: 7px solid #1086c1;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .tooltip_text {
    display: none;
  }
}

