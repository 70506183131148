@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

body, p {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* > h1, * > h2, * > h3, * > h4, * > h5, * > h6, * > p, * > select, * > button, * > input, * > th, * > td, * > strong, * > a, * > li, * > ul, * > span {
  font-family: 'Noto Sans JP', sans-serif;
}

code {
  font-family: 'Noto Sans JP', sans-serif;
}
