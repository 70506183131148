.search {
  width: 1000px;
  background-color: white;
  margin: 200px calc((100vw - 1000px) / 2) 150px;
  border-radius: 30px;
  padding: 60px 0 70px;
  box-shadow: 0px 0px 4px #d3d3d3;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .search {
    width: 800px;
    margin: 200px calc((100vw - 800px) / 2) 150px;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .search {
    width: 500px;
    margin: 200px calc((100vw - 500px) / 2) 150px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .search {
    width: 90%;
    margin: 50px 5% 100px;
    border-radius: 15px;
  }
}

.search > h2 {
  width: 100%;
  line-height: 40px;
  color: white;
  font-size: 28px;
  color: #2B2C2C;
  text-align: center;
  margin: 0 0 15px;
}

@media (max-width: 500px) and (min-width: 0px) {
  .search > h2 {
    font-size: 20px;
  }
}

.search_bar {
  width: 150px;
  height: 5px;
  background-color: #094284;
  margin: 25px auto 60px;
}

.header_contents {
  position: relative;
  width: 750px;
  height: 80px;
  margin: 50px calc((100% - 750px) / 2) 0;
}

/* 検索box */

.search_box {
  width: 800px;
  height: 80px;
  border-radius: 3px;
  margin: 10px auto 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .search_box {
    width: 700px;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .search_box {
    width: 450px;
    height: 400px;
    display: block;
    margin: 0 25px 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .search_box {
    width: 90%;
    height: 400px;
    display: block;
    margin: 0 5% 0;
  }
}

/* header_contents_select */

.select {
  width: 200px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  height: 80px;
  padding: 0 15px;
  background-color: white;
  color: #222222;
  text-decoration: none;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .select {
    width: 160px;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .select {
    width: calc(100% - 30px);
    margin: 0 0 30px;
  }
}

.select > p {
  width: 100%;
  line-height: 20px;
  font-size: 12px;
  color: #222222;
  font-weight: bold;
  margin: 10px 0 0 0;
}

.select > select {
  width: 100%;
  height: 40px;
  border: none;
  margin-bottom: 10px;
}

.input {
  width: 200px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 0 15px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .input {
    width: 160px;
  }  
}

@media (max-width: 800px) and (min-width: 0px) {
  .input {
    width: calc(100% - 30px);
    margin: 0 0 30px;
  }
}

.input > p {
  width: 100%;
  line-height: 20px;
  font-size: 12px;
  color: #222222;
  font-weight: bold;
  margin: 10px 0 0 0;
}


.input > input {
  width: 100%;
  height: 40px;
  border: none;
  margin-bottom: 10px;
}

/* 検索ボタン */

.search_box > a {
  width: 40px;
}

.search_box > a > button {
  border: none;
  width: 100%;
  height: 80px;
  text-align: center;
  background-color: #094284;
  border-radius: 5px;
  color: white;
  line-height: 25px;
  font-weight: bold;
  padding: 5px 0;
}

@media (max-width: 800px) and (min-width: 0px) {
  .search_box > a > button {
    font-size: 18px;
  }

  .search_box > a > button >  br {
    display: none;
  }
}

