.background {
  background-color: #EDF2F6;
}

.animationTrue {
  position: fixed;
  z-index: 10000;
  animation-name: animationTrue;
  animation-duration: 1s;
  height: 150px;
  text-align: center;
  width: 100vw;
  animation-iteration-count: 1;
  opacity: 1;
}

@keyframes animationTrue {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animationFalse {
  position: fixed;
  z-index: 10000;
  animation-name: animationFalse;
  animation-duration: 1s;
  height: 150px;
  text-align: center;
  width: 100vw;
  animation-iteration-count: 1;
  opacity: 0;
}

@keyframes animationFalse {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-150px);
  }
}


