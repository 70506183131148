/* catalog */

.catalog_all {
  width: 1100px;
  display: grid;
  grid-template-columns	: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 80px 80px;
  margin: 0 calc((100% - 1100px) / 2) 0;
}

.catalog_1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_1 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_1 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_2 {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_2 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_2 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_3 {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_3 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_3 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_4 {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_4 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_4 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_5 {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-right: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_5 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_5 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_6 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_6 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_6 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_7 {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_7 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_7 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_8 {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_8 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_8 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_9 {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  background-color: white;
}

.catalog_9 > img {
  width: 40px;
  height: 40px;
  margin: 20px 10px;
}

.catalog_9 > h4 {
  font-size: 14px;
  line-height: 40px;
  margin: 20px 0;
  color: #222222;
}

.catalog_10 {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
  border-top: 1px solid #EBEBEB;
  border-left: 1px solid #EBEBEB;
}


@media (max-width: 1100px) and (min-width: 0px) {

  .catalog_all {
    width: 440px;
    display: grid;
    grid-template-columns	: 1fr 1fr;
    grid-template-rows: 80px 80px 80px 80px 80px;
    margin: 0 calc((100% - 440px) / 2);
  }

  .catalog_2 {
    border-right: 1px solid #EBEBEB;
  }

  .catalog_3 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .catalog_4 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    border-right: 1px solid #EBEBEB;
  }

  .catalog_5 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    border-right: none;
  }

  .catalog_6 {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    border-right: 1px solid #EBEBEB;
    border-bottom: none;
  }

  .catalog_7 {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    border-right: 1px solid #EBEBEB;
    border-right: none;
    border-bottom: none;
  }

  .catalog_8 {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    border-right: 1px solid #EBEBEB;
    border-bottom: none;
  }

  .catalog_9 {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    border-top: 1px solid #EBEBEB;
  }

  .catalog_10 {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
    border-top: 1px solid #EBEBEB;
    border-left: 1px solid #EBEBEB;
  }
}


