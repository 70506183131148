.box {
  width: 300px;
  padding: 0 0px;
  height: 400px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 2px rgb(233, 233, 233);
}

@media (max-width: 1100px) and (min-width: 0px) {
  .box {
    margin-bottom: 50px;
  } 
}

.box > p {
  width: 90%;
  padding: 20px 5% 0;
  font-size: 15px;
  color: white;
  line-height: 30px;
  font-weight: bold;
  color: #333333;
}

.box > select {
  width: 90%;
  height: 40px;
  border: none;
  text-indent: 1em;
  margin: 10px 5% 0;
  border-radius: 0px;
  color: #222222;
  background-color: white;
  border: 1px solid #EBEBEB;
}

.box > input {
  width: 90%;
  height: 38px;
  border: none;
  text-indent: 1em;
  margin: 10px 5%;
  border-radius: 0px;
  color: #222222;
  background-color: white;
  border: 1px solid #EBEBEB;
}

.box > a > button {
  margin: 20px calc((100% - 150px) / 2);
  width: 150px;
  height: 39px;
  line-height: 39px;
  border: none;
  color: white;
  font-weight: bold;
  background-color: #0032A0;
}

