/* ranking */

.ranking {
  width: 1100px;
  padding: 50px 50px 50px;
  background-color: white;
  margin: 0 calc((100% - 1200px) / 2);
  border-radius: 20px;
}

@media (max-width: 1200px) and (min-width: 800px) {
  .ranking {
    width: 700px;
    padding: 50px 50px 50px;
    margin: 0 calc((100% - 800px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .ranking {
    width: 400px;
    padding: 50px 50px 50px;
    margin: 0 calc((100% - 500px) / 2);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .ranking {
    width: 90%;
    padding: 50px 5% 50px;
    border-radius: 0;
    margin: 0;
  }
}

.ranking > h4 {
  font-size: 30px;
  color: #002B5B;
  text-align: center;
  margin-bottom: 50px;
}

.ranking_lists {
  width: 100%;
}

/* box */

.ranking_box {
  width: 95%;
  height: 430px;
  background-color: white;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
}

.ranking_box > h4 {
  width: 100%;
  text-align: center;
  line-height: 50px;
  color: #222222;
  margin: 20px 0 0;
}

.ranking_box > a > h5 {
  width: 200px;
  text-align: center;
  color: #222222;
  border: 1px solid #002B5B;
  border-radius: 100px;
  padding: 10px 0;
  margin: 0 calc((100% - 200px) / 2);
}

/* item */

.ranking_box_item {
  width: 90%;
  margin: 20px 5% 20px;
  display: flex;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 20px;
  justify-content: space-between;
}

.ranking_box_item > h5 {
  font-size: 16px;
  line-height: 50px;
  font-size: 22px;
}

.ranking_box_item > img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0 10px;
  border-radius: 5px;
}

.ranking_box_item > div {
  width: calc(100% - 100px);
}

.ranking_box_item > div > p {
  font-size: 10px;
  color: #999999;
  line-height: 20px;
  font-weight: bold;
}

.ranking_box_item > div > h5 {
  font-size: 16px;
  color: #222222;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}



