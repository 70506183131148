/* title */

.industry_title {
  position: relative;
  font-size: 35px;
  line-height: 40px;
  color: white;
  text-align: left;
  font-weight: 900;
  background-color: #094284;
  padding: 70px 100px 70px 200px;
  width: 800px;
  margin: 0 calc(100vw - 100px) 0 0;
  border-radius: 0 30px 30px 0;
  z-index: 2;
}

/* title */

.industry {
  width: 1200px;
  padding: 200px calc((100% - 1200px) / 2) 150px;
  margin-top: -100px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .industry {
    width: 1000px;
    padding: 200px calc((100% - 1000px) / 2) 150px;
  }  
}

@media (max-width: 1000px) and (min-width: 800px) {
  .industry {
    width: 800px;
    padding: 200px calc((100% - 800px) / 2) 150px;
  }  
}

@media (max-width: 800px) and (min-width: 550px) {
  .industry {
    width: 550px;
    padding: 200px calc((100% - 550px) / 2) 150px;
  }  
}

.industry > div {
  width: 250px;
  height: 280px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  margin: 0 0 50px;
}

.industry_contents_title {
  width: 50%;
  margin: 10px 5%;
  display: flex;
  justify-content: space-between;
  background-color: #FF9B04;
  padding: 5px 20%;
  border-radius: 5px;
}

.industry_contents_title > h4 {
  width: 50%;
  font-size: 16px;
  color: white;
  line-height: 40px;
  text-align: left;
}

.industry_contents_title > img {
  width: 35px;
  height: 35px;
  margin: 2.5px;
  object-fit: contain;
}

.industry_contents_items {
  margin: 10px 10% 0;
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #EBEBEB;
}

.industry_contents_items > h5 {
  line-height: 40px;
  color: #222222;
}

.industry_contents_icon {
  width: 20px;
  height: 20px;
  color: #222222;
  margin: 10px 0;
}

.decoy_1 {
  width: 250px;
  height: 280px;
  opacity: 0;
  display: none;
}

@media (max-width: 1200px) and (min-width: 800px) {
  .decoy_1 {
    display: block;
  }  
}


