.blog {
  width: 1200px;
  padding: 100px calc((100% - 1200px) / 2);
  background-color: white;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .blog {
    width: 1000px;
    padding: 100px calc((100% - 1000px) / 2);
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .blog {
    width: 80%;
    padding: 100px 10%;
  }
}

@media (max-width: 700px) and (min-width: 0) {
  .blog {
    width: 250px;
    padding: 100px calc((100% - 250px) / 2);
  }
}

.blog > h4 {
  font-size: 30px;
  color: #094284;
  text-align: center;
  margin-bottom: 50px;
}

.blog_contents {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blog_contents > a > div {
  width: 250px;
  margin-top: 50px;
}

.blog_contents > a > div > img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.blog_contents > a > div > h5 {
  color: #666666;
  font-size: 14px;
  margin: 30px 5% 0;
  width: 90%;
}

.blog_contents > a > div > h4 {
  color: #222222;
  font-size: 16px;
  line-height: 26px;
  margin: 10px 5% 0;
  width: 90%;
}




