/* ナビゲーション */

.nav {
  position: fixed;
  width: 1100px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  z-index: 10000;
  background-color: white;
  margin: 30px calc((100vw - 1200px) / 2) 0;
  padding: 0 50px;
  border-radius: 20px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .nav {
    width: 900px;
    margin: 30px calc((100vw - 1000px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .nav {
    width: 750px;
    padding: 0 25px;
    margin: 30px calc((100vw - 800px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 500px) {
  .nav {
    width: 85%;
    padding: 0 2.5%;
    margin: 30px 5% 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .nav {
    height: 80px;
    width: 85%;
    padding: 0 2.5%;
    margin: 30px 5% 0;
    border-radius: 10px;
  }
}

/* ナビゲーション */

.nav_title {
  display: flex;
  height: 50px;
  margin: 25px 0;
}

@media (max-width: 500px) and (min-width: 0px) {
  .nav_title {
    margin: 15px 0;
  }
}

.nav_title > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.nav_title > div {
  margin: 5px 0 5px 20px;
}


@media (max-width: 500px) and (min-width: 0px) {
  .nav_title > div {
    margin: 5px 0 5px 10px;
  }
}

.nav_title > div > h1 {
  width: 100%;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  color: #094284;
  text-align: center;
  font-weight: 600;
}

.nav_title > div > h2 {
  width: 100%;
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  color: #222222;
  text-align: center;
  font-weight: 600;
}

/* 右側 */

.nav_link {
  display: flex;
  justify-content: space-between;
  width: 480px;
  height: 50px;
  margin: 25px 0;
}

@media (max-width: 800px) and (min-width: 0px) {
  .nav_link {
    display: none;
  }
}

.nav_link > h6 {
  font-size: 14px;
  line-height: 20px;
  margin: 2.5px 0;
  text-align: center;
}

.nav_link > select {
  width: 150px;
  height: 49px;
  border-radius: 5px;
  border: .5px solid #cccccc;
  background-color: #fafafa;
  border-radius: 10px;
  text-indent: 1em;
}

.nav_link > p {
  font-size: 16px;
  line-height: 50px;
  font-weight: bold;
  color: #262626;
}

.nav_link > a > button {
  border: none;
  width: 70px;
  text-align: center;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  margin: 5px 0;
  background-color: #094284;
  border-radius: 10px;
  color: white;
}

/* phone */

.search_box {
  width: 40px;
  height: 40px;
  background-color: #094284;
  border-radius: 10px;
  margin: 30px 0
}

@media (max-width: 10000px) and (min-width: 800px) {
  .search_box {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .search_box {
    margin: 20px 0
  }
}

.search_box_icon {
  width: 20px;
  height: 20px;
  color: white;
  padding: 10px;
}


